@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Lato");

$animate: all 0.2s ease-in-out;
$darkBlue: darkBlue;

.button.in-pricing {
    display: block;
    margin-top: 24px;
    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
}

.watchlistBodyContainer {
    position: relative;
    padding-bottom: 60px;
}

.watchlistBody {
    overflow-y: scroll;
    margin-bottom: 100px;
    height: 100%;
}

.newsEarningsPage {
    overflow: hidden;
}

.optionsHeader {
    display: flex;
    gap: 30px;
    height: 40px;
    align-items: center;
    justify-content: center;
    flex-flow: row;
}

.btn {
    padding: 8px 16px;
    border-radius: 8px;
    border: 0;
}

.btn.danger {
    background-color: darkred;
    color: rgb(255, 177, 177);
}

.optionsHeader h4 {
    margin: 0;
}

.optionsArrow { 
    rotate: 90deg;
}

.optionsArrow.toggled {
    rotate: -90deg;
}

.optionsWatchlist {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.95);
    z-index: 9999;
    text-align: center;
    padding: 12px 0;
    width: 100%;
    max-height: 45px;
    transition: max-height 0.4s ease-in-out .2s;
    color: rgb(210, 210, 210);
}

.optionsInner {
    padding: 24px 0;
    width: 100%; /* Set the width you need */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent content from wrapping */
}

/* Adjustments for better readability */
.optionsInner div {
    display: inline-block;
    margin-right: 20px;
}

.optionsToggle {
    margin-left: 12px;
}

.whiteBg { 
    background-color: black;
}

.optionsWatchlist.toggled {
    max-height: max-content;
}

.optionsInterest {
    border: 1px solid whitesmoke;
    border-radius: 4px;
    font-weight: 600;
    border-collapse: collapse!important;
    padding: 4px 12px;
}

.optionsInterest.decreased {
    color: rgb(71, 11, 11);
    border-color: rgb(75, 0, 0);
    background: lightpink;
}

.optionsInterest.increased {
    color: darkgreen;
    border-color: darkgreen;
    background: rgb(213, 252, 213);
}

.newsEarningsHeader {
    overflow: hidden;
    margin: auto 2%;
    border-bottom: 1px solid white;
    color: white;
}

.newsHeader {
    float: left;
}

.earningsHeader {
    float: right;
}

.newsEarningBody {
    margin-top: 1em;
}

.newModal {
    position: absolute;
    width: 100%;
    height: 100%;
}
.newsFeed {
    float: left;
    width: 60%;
    margin-left: 3%;
}

.newsFilters {
    overflow: hidden;
    margin: auto 2%;
}

.reportsFilters {
    overflow: hidden;
    margin: auto 10%;
}

.newsFilterElement {
    display: inline-block;
    margin: 0.5em auto;
    margin-left: 1%;
    vertical-align: middle;
}

.newsFilterButton {
    color: white;
    background-color: black;
    border: solid white;
    border-radius: 7px;
    padding: 5px;
    width: 120px;
    font-size: 1.3vw;
}

.newsDatePickerButton {
    color: white;
    background-color: black;
    border: none;
    font-size: 1.1vw;
}

.reportFilterButton {
    display: inline-block;
    align-items: center;
    font-size: 1.3vw;
    color: white;
    background-color: black;
    border: solid white;
    border-radius: 0px;
    padding: 5px;
    width: 50%;
}

.newsFilterSearchbar {
    overflow: hidden;
    vertical-align: middle;
    margin-right: 2%;
}

.newsFilterSearchbarElement {
    display: inline-block;
    margin: 0;
    vertical-align: left;
}

.newsSearchText {
    width: 40%;
    color: white;
    font-weight: bold;
    font-size: 1.2vw;
    margin-right: 5%;
}

.newsSearchbar {
    padding: 0;
    border: 0;
    width: 50%;
    color: white;
    background-color: black;
    border-bottom: solid white;
}

.newsArticle {
    margin: auto;
    width: 100%;
    border-bottom: 1px solid goldenrod;
}

.articleImage {
    display: inline-block;
    height: 140px;
    width: 140px;
    align-content: center;
    align-self: center;
    margin: 1%;
    margin-bottom: 10px;
}

.articleBody {
    display: inline-block;
    overflow: hidden;
    margin: 1% 0;
    height: 160px;
    width: 75%;
}
.articleBodyNoImg {
    display: inline-block;
    overflow: hidden;
    margin: 1% 0;
    height: 160px;
    width: 100%;
}

.articleElement {
    margin: 0 0;
    width: 80%;
    display: inline-block;
}

.articleDate {
    margin: 0;
    font-size: 0.8em;
    float: right;
    color: white;
}

.articleKeywords {
    height: 20%;
    width: 70%;
    overflow: hidden;
}

.articleTitle {
    height: 20%;
    width: 100%;
    margin-top: 1%;
    font-weight: bold;
    font-size: 1em;
    color: white;
}

.newsTicker {
    display: inline;
    color: white;
    background-color: black;
    border: 2px solid silver;
    border-radius: 0px;
    padding: 1px 3px;
    //width: 11%;
    margin: 1%;
    font-size: 1vw;
}

.articleDescription {
    margin-top: 1%;
    height: 40%;
    font-size: 0.8em;
    color: white;
}

.pageSelect {
    margin: auto;
    width: 50%;
    text-align: center;
    margin-top: 1em;
}

.pageSelect button {
    display: inline;
    color: white;
    background-color: black;
    border: solid white;
    border-radius: 7px;
    padding: 5px;
    font-size: 1em;
    width: 95px;
}

.pageSelect p {
    display: inline;
    margin: 0 1em;
}

.earningReports {
    float: right;
    width: 32%;
    margin-right: 2%;
    border-left: 1px solid white;
}

.reportsTable {
    max-height: 3162px;
}

.reportTableHeaders > th {
    width: 33%;
    font-size: 1.2vw;
}

.reportTableSearchbar {
    margin: 5%;
    width: 20%;
    background-color: black;
    color: white;
    border-bottom: solid white;
    border-top: none;
    border-right: none;
    border-left: none;
}

.reportTableRow {
    border-bottom: 1px solid goldenrod;
}

.reportTableRow > td {
    padding: 1% 0;
    padding-left: 0;
    width: 33%;
    font-size: 1vw;
}

.reportTableButton {
    color: white;
    background-color: black;
    border: solid silver;
    border-radius: 0px;
    padding: 5px 15px;
    font-size: 1vw;
    width: 75px;
}

.tempTickerInput {
    text-transform: uppercase;
}

.popUp {
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.popUpBackground {
    width: 100%;
    height: 100%;
}

.popUpBody {
    position: absolute;
    z-index: 2;
    left: 40%;
    right: 40%;
    top: 45%;
    bottom: 45%;
    background-color: whitesmoke;
    border-radius: 5px;
}

.popUpClose {
    float: right;
    text-align: center;
    background-color: red;
    margin: 0;
    padding: auto;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
}
.popUpClose:hover {
    background-color: whitesmoke;
    color: red;
}

.popUpSearchText {
    color: black;
    display: inline;
    margin-top: 25px;
    margin: 0 5%;
}
.popUpSearchBar {
    display: inline;
    margin-top: 25px;
    margin-right: 5%;
}

.sortToggle {
    display: inline;
    height: 1em;
    width: 1.3em;
}

// .time{
//   display: inline;
//   margin-left: 0.8em;
// }

body,
html {
    margin: 0;
    padding: 0;
    background-color: black;
    font-family: arial, helvetica, courier;
}

p {
    margin: 0;
    padding: 0;
}

.homepageBackground {
    background-image: url("./images/AdobeStock_308727927 [Converted].png");
    background-size: 100%;
    background-repeat: no-repeat;
}

.homepage {
    background-color: black;
    overflow: hidden;
    min-height: 100%;
    min-width: 100%;
}

.navBar {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    border: none;
}

.navElement {
    margin: 0.5em 1em;
}

.navButton {
    color: white;
    background-color: #4a4a4a;
    padding: 1em;
    border: none;
    text-decoration: none;
    text-align: center;
    justify-content: space-between;
    font-size: 20px;
    box-sizing: content-box;
    text-align: center;
    display: inline;
    appearance: auto;
    height: 10px;
    vertical-align: middle;
    line-height: 10px;
    margin: 0 5px;
    box-shadow: 3px 3px 3px black;
}

.navSub {
    background-color: #15c47c;
}

.logoNav {
    width: 25%;
}
.logoDiv {
    vertical-align: middle;
}
.optionsLogo {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}
.homepageIntro {
    background-color: rgba(0, 0, 0, 0.7);
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}
@media screen and (max-width: 995px) {
    .homepageIntro {
        background-color: rgba(0, 0, 0, 0.7);
        width: 80%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
    }
}
.homepageIntro > p {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.homepageIntro > button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.homepageIntroTitle {
    color: #d5b15e;
    font-size: 40px;
    width: 90%;
    margin-top: 50px;
    text-transform: uppercase;
}

.homepageIntroSubtitle {
    font-size: 25px;
    color: #d5b15e;
    margin-top: 10px;
}

.homepageIntroBody {
    color: white;
    font-size: 17px;
    width: 90%;
    margin-top: 10px;
}

.homepageIntroButton {
    background-color: #15c47c;
    color: white;
    border: none;
    box-shadow: 3px 3px #4a9846;
    font-size: 20px;
    padding: 25px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.optionsPreview {
    color: white;
    height: 90%;
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    margin-top: 20px;
    margin-bottom: 30px;
}

.line {
    height: 0;
    width: 95%;
    border-top: #d5b15e solid 1px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.features {
    background-color: #111111;
    overflow: hidden;
}

.features > p {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.featuresTitle {
    color: #d5b15e;
    font-size: 40px;
    text-transform: uppercase;
    margin-top: 20px;
}

.featuresBody {
    color: white;
    width: 60%;
    margin-top: 5px;
}

.featuresFlexbox {
    width: 70%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}
@media screen and (min-width: 995px) {
    .featuresElement {
        width: 30%;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 30px;
    }
}
@media screen and (max-width: 995px) {
    .featuresElement {
        width: 80%;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 30px;
    }
}

.featuresElement > img {
    width: 4cm;
    height: 4cm;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
}

.featuresElement > p {
    color: white;
    text-align: center;
}

.featuresFlexboxTitle {
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 10px;
}

.featuresLine {
    height: 0;
    width: 60%;
    border-top: #707070 solid 2px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.featuresFlexboxBody {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    margin-top: 15px;
}

.plansTitle {
    color: #d5b15e;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 40px;
}

.plansBody {
    color: white;
    text-align: center;
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.plans {
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    margin-top: 40px;
}

.planElement {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.planElement > p {
    color: white;
}

.planSilver {
    background-color: #4a4a4a;
}

.planGold {
    background-color: #bc9b4e;
}

.planElementTitle {
    font-size: 30px;
    margin-top: 30px;
    text-align: center;
    color: #ffffff;
}

.planElementPrice {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #ffffff;
}

.planElementPer {
    display: inline-block;
    width: 40%;
    text-align: left;
    font-size: 20px;
}
.navRow {
    width: 80%;
    display: flex;
    align-items: top;
    justify-content: space-between;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 10px;
    margin-left: 8%;
    margin-right: 10%;
    min-height: 150px;
}
.optionsNavBar {
    min-width: 40%;
    max-width: 40%;
    display: inline-block;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 10px;
}

.optionsNavBarInner > a {
    width: 30%;
    height: 90%;
    appearance: auto;
    overflow: hidden;
    background-color: transparent;
    border: none;
    justify-self: center;
    justify-content: center;
    text-decoration: none;
}

.optionsNavBarInner > button {
    width: 30%;
    height: 90%;
    appearance: auto;
    overflow: hidden;
    background-color: transparent;
    border: none;
}

.optionsNavBar > img {
    width: 20%;
    color: white;
    border: none;
    box-sizing: content-box;
    appearance: auto;
    vertical-align: middle;
    line-height: 10px;
}

.optionsNavBarInner > a > img {
    display: flex;
    height: 7vh;
    width: 7vh;
    align-items: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    margin: auto;
}
.optionsNavBarInner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
}

.optionsNavBarInner > button > img {
    display: flex;
    height: 7vh;
    width: 7vh;
    align-items: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    margin: auto;
}

.optionsStats {
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    height: 30%;
}

.table-sentiment-block-overall {
    width: 15%;
    display: inline-block;
    color: white;
    background-color: #4a4a4a;
    border-radius: 20px;
    overflow: hidden;
    min-height: 170px;
    position: relative;
}

.table-sentiment-block-header {
    text-align: center;
    line-height: 150%;
    font-size: 25px;
    text-transform: uppercase;
    padding: 5px 0 0;
}

.table-sentiment-block-content {
    position: relative;
    min-width: 100%;
    text-align: left;
    font-size: 30px;
    margin: 5%;
    margin-top: 15%;
}

.table-sentiment-percent {
    display: inline-block;
    overflow: hidden;
    height: 75px;
    width: 75px;
    position: absolute;
    right: 5%;
    top: 30%;
}

.circle {
    display: inline-block;
    height: 75px;
    width: 75px;
    background-color: #393737;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
}

.percent {
    position: absolute;
    display: inline-block;
    min-width: 100%;
    min-height: 50%;
    background-color: brown;
    z-index: 1;
    bottom: 0;
}

.percentText {
    position: absolute;
    min-height: 75px;
    min-width: 100%;
    z-index: 2;
    text-align: center;
    line-height: 75px;
    font-weight: bold;
    font-size: 25px;
}

.overall-sentiment {
    background-color: #313e85;
}

.call-sentiment {
    background-color: #16c97e;
}

.put-sentiment {
    background-color: #ff3f5c;
}

.table-sentiment-block-flows {
    width: 15%;
    display: inline-block;
    color: white;
    // background-color: #4a4a4a;
    border-radius: 20px;
    overflow: hidden;
    min-height: 170px;
    position: relative;
}

.table-sentiment-flow {
    border-radius: 20px;
    overflow: hidden;
    background-color: #4a4a4a;
    margin-top: 0px;
    height: 74px;
}

.sentimentSpace {
    min-height: 20px;
    background-color: black;
}

.table-sentiment-flow-content {
    text-align: center;
    padding: 0 0;
    font-size: 20px;
    max-width: 150%;
}

.verticalLine {
    width: 0;
    display: inline-block;
    border-left: 1px solid white;
}

.topTicker {
    width: 65%;
    display: inline-block;
}

.topTicker button {
    margin: 3px;
    background-color: transparent;
    color: white;
    border-radius: 3px;
}

.neutral {
    border: 2px solid #313e85;
}

.calls {
    border: 2px solid #16c97e;
}

.puts {
    border: 2px solid #ff3f5c;
}

.watchlistSidebar {
    display: inline-block;
    position: absolute;
    right: -40%;
    left: 100%;
    top: 0%;
    bottom: 20%;
    transition: 0.5s;
    max-width: 20%;
    z-index: 1000;
}

.watchlistSidebar.open {
    display: inline-block;
    position: absolute;
    right: 0;
    left: 80%;
    //top: 0;
    bottom: 20%;
    transition: 0.5s;
    min-height: 100%;
    overflow: auto;
    max-height: calc(100vh - 40px);
}

.watchlistToggleBackground {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.watchlistToggle {
    margin: 0;
    // top: 45px;
    position: relative;
    background-color: black;
    right: 50px;
    height: 35px;
    width: 40px;
    transition: 0.5s;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    opacity: 0.5; /* Standard compliant browsers */
    -moz-opacity: 0.5; /* Firefox and Mozilla browsers */
    -webkit-opacity: 0.5; /* WebKit browser e.g. Safari */
    filter: alpha(opacity=50); /* For IE8 and earlier */
    padding: 0px 0;
    color: white;
}
.toggleButton {
    background-color: black;
}
.textArrow {
    font-size: 40px;
    align-items: start;
    padding-bottom: 30px;
    color: white;
}

.watchlistToggle.open {
    right: auto;

    position: absolute;
    left: 0%;
    width: 40px;
    transition: 0.5s;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

// .watchlistToggle.toggleGlow{
//   border: 1px solid white;
// }
button {
    color: white;
    background-color: black;
}
.watchlistToggle.open img {
    transform: scaleX(-1);
}

.watchlistToggle button {
    height: 100%;
    width: 100%;

    margin: 0;
    border: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
}

.watchlistToggle img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.watchlistHeader {
    width: 90%;
    height: 35px;
    border-bottom: 2px solid white;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.watchlistTabs {
    width: 80%;
    height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.watchlistTabText {
    width: 45%;
    height: 100%;
    display: inline-block;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.watchlistTabText > p {
    padding-top: 10px;
}

.watchlistTabOpen {
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
}

.watchlistTabOpen > p {
    font-weight: bold;
}

.watchlistBody {
    border-left: 2px solid white;
    color: white;
    height: max-content;
    min-height: 600px;
}

.banner {
    width: 100%;
    height: 150px;
    background-size: cover; 
    display: "flex";
    justify-content: center;
    align-items: center; 
}

.bannerText {
    font-size: large;
}

.searchBtn {
    margin-left: 48px;
}

.filterTabElement {
    width: 95%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.filterTabElement > p {
    width: 30%;
    display: inline-block;
    margin-left: 15px;
}

.filterTabElement > input {
    padding: 0;
    padding-left: 1px;
}

.filterTabElement .react-datepicker-wrapper {
    width: 55%;
    display: inline-block;
    padding: 0;
    padding-left: 1px;
}
.filterTabElement .react-datepicker__input-container {
    width: 100%;
}

.filterTabElement .react-datepicker__input-container > input {
    width: 100%;
    padding: 0;
}

.filterTabFirstBox {
    display: flex;
    flex-direction: column;
}

.filterTabFirstBox label {
    text-transform: uppercase;
}

.filterTabSecondBox {
    display: flex;
    flex-direction: column;
    height: 160px;
    flex-wrap: wrap;
    width: 85%;
    margin-left: 30px;
}

.filterTabSecondBox label {
    text-transform: uppercase;
}

.filterTabSecondBox > div {
    height: 20px;
    width: 50%;
}

.watchlistTabHeader {
    width: 100%;
    height: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    padding-top: 5px;
}

.filterTabHeader {
    margin-top: 30px;
}

.watchlistTabTickerEdit {
    width: 95%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.watchlistTabTickerEditHeader {
    width: 100%;
    font-weight: bold;
}

.watchlistTabAdd {
    width: 40%;
    display: inline-block;
    margin-top: 10px;
}

.watchlistTabAdd > p {
    width: 40%;
    display: inline-block;
    text-align: right;
}

.addBar {
    width: 40%;
    display: inline-block;
    margin-left: 5px;
}

.watchlistTabRemove {
    width: 60%;
    display: inline-block;
    margin-top: 10px;
}

.watchlistTabRemove > p {
    width: 40%;
    display: inline-block;
    text-align: right;
}

.removeBar {
    width: 40%;
    display: inline-block;
    margin-left: 5px;
}

.watchlistTabWatchedTickers {
    width: 100%;
    min-height: 200px;
}

.watchlistTabWatchedTickersHeader {
    width: 95%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    margin-top: 20px;
}

.watchlistTabTickerBox {
    margin-top: 10px;
    width: 95%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-content: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.tickerFilter {
    width: 18%;
    margin: 2px;
    background-color: transparent;
    color: white;
    border-radius: 3px;
    border: 2px solid white;
    font-size: 12px;
    overflow: hidden;
    padding: 5px 0;
    text-transform: uppercase;
}

.switch {
    position: absolute;
    display: inline-block;
    width: 45px;
    height: 26px;

    top: 45px;
    right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: #ff3f5c;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #16c97e;
}

input:focus + .slider {
    box-shadow: 0 0 1px #16c97e;
}

input:checked + .slider:before {
    background-color: white;
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.watchlistSearch {
    width: auto;
    margin: 0.5em;
    display: flex;
    border-bottom: 1px solid white;
}

.tickerSearchbar {
    color: white;
    margin: 0.5em;
    padding: 0;
    width: 50%;
}

.tickerSearchbar > p {
    margin: 0;
    padding: 0;
    text-align: right;
}

.tickerSearchbar > input {
    padding: 0;
    border: none;
    width: 50%;
}

.watchlistTickers {
    color: white;
    margin: 0 0.5em;
}

.watchlistTickers > h4 {
    text-align: left;
    font-size: 0.9em;
}

.currentTickers {
    width: 95%;
    margin: auto;
}

table {
    border-collapse: collapse;
    width: 95%;
    color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    /*box-shadow: 0 0px 40px 0px rgba(14, 14, 14, 0.15);
  -moz-box-shadow: 0 0px 40px 0px rgba(14, 14, 14, 0.15);
  -webkit-box-shadow: 0 0px 40px 0px rgba(14, 14, 14, 0.15);
  -o-box-shadow: 0 0px 40px 0px rgba(14, 14, 14, 0.15);
  -ms-box-shadow: 0 0px 40px 0px rgba(14, 14, 14, 0.15);*/
    background-color: transparent; //#303030;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

td {
    text-align: center;
}

.optionsTableHeaders {
    border-bottom: 2px solid white;
}

.time {
    display: inline;
    margin-left: 0.8em;
}

.even {
    background-color: #212121;
}

.GOLDEN {
    background-color: #cba73b;
}

.GOLDEN.even {
    background-color: #b99425;
}

.SILVER {
    background-color: #b4b4b4;
}

.SILVER.even {
    background-color: #8f8f8f;
}

.ROSE {
    background-color: #d29496;
}

.ROSE.even {
    background-color: #bd6f71;
}

.downloadBtn {
    background: green;
    border-radius: 8px;
    color: white;
    margin-top: 80px;
    margin-left: 10%;
    padding: 6px 8px;
    font-size: 12px;
    cursor: pointer;
}

.tableWatchlistContainer {
    position: relative;
    margin-top: 1em;
    overflow: hidden;
    min-height: 700px;
}

.stockTable {
    margin-right: 2.5em;
}

.stockOptionsTable {
    width: 100%;
    font-size: 90%;
    display: inline-block;
    transition: 0.5s;
    overflow-x: auto;
}

.stockOptionsTable.open {
    width: 80%;
}

.quickWatchlist {
    position: relative;
    margin: 0;
    padding: 0;
    width: 50px;
    left: 3%;
}

.CALL {
    .ticker {
        background: transparent;
        border: 2px solid #11a869;
        border-radius: 5px;
        text-align: center;
        margin: auto;
        margin-top: 1px;
        margin-bottom: 1px;
        width: 4em;
    }

    .ticker:hover {
        background: #11a869;
        border: 2px solid #f5f5f5;
    }
}

.PUT {
    .ticker {
        background: transparent;
        border: 2px solid #ff2647;
        border-radius: 5px;
        text-align: center;
        margin: auto;
        margin-top: 1px;
        margin-bottom: 1px;
        width: 4em;
    }

    .ticker:hover {
        background: #ff2647;
        border: 2px solid #f5f5f5;
    }
}

.pagination {
    text-align: center;
    color: white;
    margin-left: auto;
    margin-right: auto;
}

.react-responsive-modal-root {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
}

.react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

.react-responsive-modal-container {
    outline: 0;
    overflow: hidden;
    text-align: center;
    width: 90%;
    height: 580px;
    margin-top: 5%;
    max-height: 80%;
    max-width: 800;
    margin-left: auto;
    margin-right: auto;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
    width: 0;
    height: 100%;
    content: "";
    display: inline-block;
    vertical-align: middle;
}

.react-responsive-modal-modal {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #141414;
    box-shadow: 15px rgba(0, 0, 0, 0.5);
    margin: 1.2rem;
    padding: 1.2rem;
    position: relative;

    min-width: 90%;

    border-radius: 10px;
}

.react-responsive-modal-closeButton {
    position: absolute;
    top: 14px;
    right: 14px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: white;
    display: flex;
}

@keyframes react-responsive-modal-overlay-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes react-responsive-modal-overlay-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes react-responsive-modal-modal-in {
    0% {
        transform: scale(0.96);
        opacity: 0;
    }
    100% {
        transform: scale(100%);
        opacity: 1;
    }
}

@keyframes react-responsive-modal-modal-out {
    0% {
        transform: scale(100%);
        opacity: 1;
    }
    100% {
        transform: scale(0.96);
        opacity: 0;
    }
}

.subNewsEarningBody {
    margin-top: 1em;
}

.subNewsFeed {
    float: right;
    width: 40%;
    margin-left: 3%;
    max-height: 60%;
    overflow-y: scroll;
    height: 500px;
}
.trading {
    max-height: 400;
    background-repeat: repeat-y;
    overflow-y: hidden; /* code added to prevent scroll */
    position: absolute;
    float: left;
    width: 55%;
}

.subNewsArticle {
    margin: auto;
    width: 100%;
    border-bottom: 1px solid goldenrod;
}

.subArticleImage {
    display: inline-block;
    height: 120px;
    width: 120px;
    margin: 1%;
    align-items: top;
    border-color: black;
}

.subArticleBody {
    display: inline-block;
    overflow: hidden;
    margin: 1% 0;
    height: 20%;
    width: 75%;
}
.subArticleBodyNoImg {
    display: inline-block;
    overflow: hidden;
    margin: 1% 0;
    height: 20%;
    width: 100%;
}

.subArticleElement {
    margin: 0 0;
    width: 80%;
    display: inline-block;
}

.subArticleTitle {
    height: 20%;
    width: 100%;
    margin-top: 1%;
    font-weight: bold;
    font-size: 1.1em;
    color: white;
    font-family: arial, helvetica, courier;
}

.subNewsTicker {
    display: inline;
    color: white;
    background-color: black;
    border: 2px solid silver;
    border-radius: 10px;
    padding: 1px 3px;
    //width: 11%;
    margin: 1%;
    font-size: 0.8vw;
}

.subArticleDescription {
    margin-top: 1%;
    height: 40%;
    font-size: 0.8em;
    color: white;
    font-family: arial, helvetica, courier;
}

h1 {
    font-size: 32px;
    text-align: center;
    color: white;
    font-family: arial, helvetica, courier;
    text-align: center;
    color: white;
    margin-top: 10px;
    margin-bottom: 15px;
}

.pagination > button {
    margin: 0 5px;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.pagination > button:disabled {
    border: 2px solid transparent;
    color: transparent;
}

// SOCIAL MEDIA
/* Style all font awesome icons */
/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
    display: block;
    text-align: center;
    // padding: 12px;
    transition: all 0.3s ease;
    color: #f5f5f5;
    height: 1.6em;
    width: 1.6em;
    line-height: 1.6em;
    font-size: 1em;
    border-radius: 50%;
    margin-bottom: 10px;
    background: #141414;
    color: #f5f5f5;
}

/* Style the social media icons with color, if you want */
.icon-bar {
    .facebook:hover {
        background: #1877f2;
    }

    .twitter:hover {
        background: #55acee;
    }

    .youtube:hover {
        background: red;
    }

    .instagram:hover {
        background: #8134af;
    }

    .discord:hover {
        background: #7289da;
    }

    .stocktwits:hover {
        background: #218dff;
    }
}

.discord-link {
    color: white;
}

// END SOCIAL MEDIA

footer {
    text-align: center;
    padding: 3px;
    font-size: 12px;
    color: #f5f5f5;
    margin-top: 200px;
}

hr.solid {
    border-top: 1px solid #bbb;
}

.vertBar {
    display: inline;
    margin-left: 2.5%; /* Override in-line if you want specific height. */
    margin-right: 2.5%;
}

.center {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-align: center;
}

#examples {
    display: flex;
    //justify-content: space-between;
    flex-flow: row;
    max-width: 100%;
    width: 95%;
    opacity: 0.95;
    padding: 0px 25px;
    margin: 0 auto 30px;
    border-radius: 20px;

    // h1 {
    //   font-size: 48px;
    //   text-align: center;
    //   margin: 30px 15px 60px;
    // }

    .example {
        display: flex;

        flex-flow: row nowrap;
        align-items: center;
        margin: 10 0 20x;
        text-decoration: none;
        .hover {
            font-size: 16px;
            text-align: center;
            margin: 0 auto;
            padding: 10px;
            transition: $animate;
            position: relative;
            &:before,
            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                width: 0px;
                height: 2px;
                margin: 2px 0 0;
                transition: $animate;
                transition-duration: 0.75s;
                opacity: 0;
            }
            &.hover-1 {
                &:before,
                &:after {
                    left: 0;
                }
            }
            &.hover-2 {
                &:before,
                &:after {
                    right: 0;
                }
            }
            &.hover-3 {
                &:before {
                    left: 50%;
                }
                &:after {
                    right: 50%;
                }
            }
            &.hover-4 {
                &:before {
                    left: 0;
                }
                &:after {
                    right: 0;
                }
            }
        }
        &:hover {
            cursor: pointer;
            .hover {
                &:before,
                &:after {
                    width: 100%;
                    opacity: 1;
                }
                &.hover-3,
                &.hover-4 {
                    &:before,
                    &:after {
                        width: 50%;
                    }
                }
            }
        }
    }
}
#examples {
    display: block;
    width: auto;
}
#examples > li {
    border: none;
}
nav li a {
    display: inline-block;
    color: #f5f5f5;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    float: left;
}
nav p {
    color: #f5f5f5;
    text-align: center;
    text-decoration: none;
    padding-top: 5px;
    max-height: 50px;
    min-height: 50px;
}

.nav-border-right {
    border-right: 1px solid #f5f5f5;
}

nav li button {
    color: #f5f5f5;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
    background: rgba(14, 14, 14, 0);
    border: none;
    color: #f5f5f5;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    float: right;
    margin-right: 20px;
    outline: none;
    //border-left: 1px solid #f5f5f5;
}

nav button:hover {
    cursor: pointer;
}

.selected {
    font-weight: bold;
}

.discord-link {
    color: white;
}

.rightSide {
    padding: 5px 5px;
    //margin-top: 30px;
    .example {
        button {
            padding-bottom: 1px;
        }
    }
}

.section.purple {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.container-3 {
    margin-bottom: -1px;
}

.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
}

.w-container:before,
.w-container:after {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.w-container:after {
    clear: both;
}

.w-container .w-row {
    margin-left: -10px;
    margin-right: -10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    margin-bottom: 10px;
}

h3-ital {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
    margin-top: 20px;
    font-style: italic;
}

.heading-6 {
    margin-top: 50px;
    font-family: Montserrat, sans-serif;
    color: #f5f5f5;
    font-size: 40px;
}

strong {
    font-weight: bold;
}

.section-subtitle {
    margin-bottom: 26px;
    color: #f5f5f5;
    font-size: 24px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
}

.w-row:before,
.w-row:after {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.w-row:after {
    clear: both;
}

.w-row .w-row {
    margin-left: 0;
    margin-right: 0;
}

.asSeenOn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
    height: 60px;
    margin-top: 50px;
    background-color: rgba($color: #000000, $alpha: 0.7);
    border-radius: 10px;
}

.asSeenOnText {
    height: 60px;
    display: inline-block;
    font-size: 40px;
    color: #d5b15e;
    font-weight: bold;
    width: 30%;
    text-align: end;
}

.asSeenOnContent {
    display: inline-block;
    height: 60px;
    width: 70%;
}

.asSeenOnLogos {
    display: flex;
    height: 60px;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    margin: 0;
}

.asSeenOnImages {
    height: 60%;
}

.flex-container-profile {
    display: block;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.flex-column {
    display: inline-block;
    align-items: center;
    width: 47%;
    background: black;
    margin: 0 1%;
    border-radius: 19px;
    border: 1px solid #c59a4d;
    overflow: hidden;
    height: 500px;
    position: relative;
}

.flex-column-header {
    background: #a68a53;
    color: white;
    width: 100%;
    padding: 5px;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
}

.accountFullRow {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.accountFullRow > p {
    display: inline-block;

    color: white;
}

.accountFullRow40Width {
    width: 40%;
}

.accountFullRow > input {
    display: inline-block;
    width: 60%;
    margin: 0;
    border: 0;
    padding: 0;
}

.accountFullRow input {
    display: inline-block;
    margin: 0;
    border: 0;
    padding: 1px 0;
}

.accountMajorityRow {
    display: inline-block;
    width: 60%;
}

.accountMajorityRow > p {
    display: inline-block;
    width: 40%;
    color: white;
}

.accountMajorityRow > input {
    display: inline-block;
    width: 55%;
    margin-right: 5%;
}

.accountMinorityRow {
    display: inline-block;
    width: 40%;
}

.accountMMYY {
    display: inline-block;
    width: 40%;
    color: white;
}

.accountMMYYBar {
    display: inline-block;
    width: 60%;
}

.accountCVC {
    display: inline-block;
    width: 30%;
    color: white;
}

.accountCVCBar {
    display: inline-block;
    width: 70%;
}

.accountBold {
    font-weight: bold;
    font-size: 18px;
}

.accountHeader {
    font-weight: bold;
    width: 40%;
    font-size: 18px;
}

.accountInfo {
    width: 60%;
    font-size: 18px;
}

.accountCardsRow {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.accountCard {
    display: inline-block;
    height: 50px;
    margin-top: 40px;
    margin-right: 5px;
}

.accountCard > img {
    object-fit: contain;
    height: inherit;
}

.accountEdit {
    border: 0;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #a68a53;
    padding: 5px 15px;
    margin-bottom: 20px;
}

.accountRight {
    float: right;
    border: 0;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #a68a53;
    padding: 5px 15px;
    margin-bottom: 20px;
}

.accountEditButtons {
    margin: 0 5% 20px;
    width: 40%;
}

.accountSubBackground {
    display: inline-block;
    width: 30%;
    background-color: #4a4a4a;
    border-radius: 20px;
    position: absolute;
    top: 60px;
    bottom: 45px;
}

.ASB1 {
    left: 1.5%;
    right: 31.5%;
}

.ASB2 {
    left: 34.5%;
    right: 64.5%;
}

.ASB3 {
    left: 67.5%;
    right: 97.5%;
}

.accountSubBackground > p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.accountSubHeader {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: white;
    margin-top: 5px;
}

.accountSubPrice {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: 5px;
}

.ASC {
    color: white;
    font-size: 13px;
    margin-top: 10px;
}

.accountSubButton {
    position: absolute;
    bottom: 15px;
    left: 10%;
    right: 10%;
    width: 80%;
    border: 0;
    background-color: #a68a53;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 0;
    border-radius: 10px;
}

.accountSubCancel {
    color: white;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
}

@media screen and (min-width: 995px) {
    .w-col {
        position: flex;
        float: left;
        width: 100%;
        height: 75%;
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media screen and (max-width: 995px) {
    .w-col {
        position: flex;
        float: center;
        width: 100%;
        min-height: 1px;
        padding-left: 10px;
        padding-right: 0px;
    }
}
.w-col .w-col {
    padding-left: 0;
    padding-right: 0;
}

.w-col-1 {
    width: 8.33333333%;
}

.w-col-2 {
    width: 16.66666667%;
}

.w-col-3 {
    width: 25%;
}

@media screen and (max-width: 995px) {
    .w-col-4 {
        width: 90%;
        min-height: 40%;
        margin-bottom: 5%;
        text-align: center;
        align-content: center;
        align-self: center;
    }
    .div-block-2 {
        height: 1px;
        line-height: 1px;
    }
}
@media screen and (min-width: 995px) {
    .w-col-4 {
        width: 31.2%;
        height: 75%;
    }
    .div-block-2 {
        height: 10px;
        line-height: 19px;
    }

    .div-block-3 {
        height: 42px;
        line-height: 19px;
    }

    .div-block-4 {
        height: 39px;
        line-height: 19px;
    }

    .div-block-5 {
        height: 67px;
        line-height: 19px;
    }

    .div-block-6 {
        height: 123px;
        line-height: 19px;
    }
}
.w-col-5 {
    width: 41.66666667%;
}

.w-col-6 {
    width: 50%;
}

.w-col-7 {
    width: 58.33333333%;
}

.w-col-8 {
    width: 66.66666667%;
}

.w-col-9 {
    width: 75%;
}

.w-col-10 {
    width: 83.33333333%;
}

.w-col-11 {
    width: 91.66666667%;
}

.w-col-12 {
    width: 100%;
}

.w-hidden-main {
    display: none !important;
}

@media screen and (max-width: 995px) {
    .w-container {
        max-width: 728px;
    }
    .w-hidden-main {
        display: inherit !important;
    }
    .w-hidden-medium {
        display: none !important;
    }
    .w-col-medium-1 {
        width: 8.33333333%;
    }
    .w-col-medium-2 {
        width: 16.66666667%;
    }
    .w-col-medium-3 {
        width: 25%;
    }
    .w-col-medium-4 {
        width: 33.33333333%;
    }
    .w-col-medium-5 {
        width: 41.66666667%;
    }
    .w-col-medium-6 {
        width: 50%;
    }
    .w-col-medium-7 {
        width: 58.33333333%;
    }
    .w-col-medium-8 {
        width: 66.66666667%;
    }
    .w-col-medium-9 {
        width: 75%;
    }
    .w-col-medium-10 {
        width: 83.33333333%;
    }
    .w-col-medium-11 {
        width: 91.66666667%;
    }
    .w-col-medium-12 {
        width: 100%;
    }
    .w-col-stack {
        width: 100%;
        left: auto;
        right: auto;
    }
}

html.w-mod-js *[data-ix="fade-in-on-load"] {
    opacity: 0;
    -webkit-transform: translate(0px, 24px);
    -ms-transform: translate(0px, 24px);
    transform: translate(0px, 24px);
}

html.w-mod-js *[data-ix="fade-in-on-scroll"] {
    opacity: 0;
    -webkit-transform: translate(0px, 24px);
    -ms-transform: translate(0px, 24px);
    transform: translate(0px, 24px);
}

.heading-5 {
    font-family: "PT Sans", sans-serif;
    text-align: center;
    color: #141414;
}

h2 {
    font-size: 32px;
    line-height: 36px;
    margin-top: 20px;
    text-align: center;
}

h3 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 20px;
}

.plan-wrapper {
    height: 75%;
    padding: 17px 17px 19px;
    border-radius: 10px;
}
.addl-feature {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
}
.plan-wrapper-2 {
    height: 100px;
    border-radius: 10px;
    background-color: #f5f5f5;
    color: #2b2b2b;
}
.button {
    display: inline-block;
    margin-right: 14px;
    padding: 13px 23px;
    border-radius: 20px;
    background-color: #141414;
    box-shadow: 0 2px 1px 0 rgba(14, 14, 14, 0.12);
    -webkit-transition: background-color 300ms ease, box-shadow 300ms ease,
        color 300ms ease;
    transition: background-color 300ms ease, box-shadow 300ms ease,
        color 300ms ease;
    font-family: "Roboto Slab", sans-serif;
    color: #f5f5f5;
    text-align: center;
    text-decoration: none;
}

.hamburger {
    display: none;
    z-index: 6;
}
@media (max-width: 767px) {
    .center {
        display: block;
        width: 100%;
    }
    #examples {
        display: block;
        width: auto;
    }
    #examples > li {
        border: none !important;
    }
}

.definition {
    font-size: 1em;
    line-height: 1.5em;
    padding-bottom: 15px;
    color: white;
}

.accordianItem {
    font-size: 1em;
    font-weight: bold;
    line-height: 1.5em;
    padding-bottom: 15px;
    color: white;
    background-color: black;
    font-family: Montserrat, sans-serif;
}
.accordion__button {
    color: white !important;
    background-color: black !important;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    cursor: pointer;
    padding: 18px;
    width: 90% !important;
    text-align: left;
    border: none;
}
.accordion__heading {
    border-top: solid white 2px;
    border-radius: 3px;
}
.accordian__button:hover {
    background-color: white;
    color: black;
}
.accordianBorder {
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
    border-color: white !important;
}
.home-table {
    margin-top: 0px;
    margin-left: 3%;
    margin-right: 3%;
    width: 93%;
}
.home-column1 {
    align-items: center;
    align: center;
    align-self: center;

    margin-bottom: 30px;
}

.home-column2 {
}

.home-column2 img {
    max-width: 100%;
}

.home-column1 {
    margin-top: 0px;
}
.vid-centered {
    width: 80%;
}
.video {
    height: 50%;
    display: block;
}

.speech {
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
}

.switchSpeech {
    position: absolute;
    display: inline-block;
    width: 45px;
    height: 26px;
}

/* Hide default HTML checkbox */
.switchSpeech input {
    opacity: 0;
    width: 0;
    height: 0;
}

.speechText {
    display: inline-block;
    width: 70%;
    text-align: right;
    font-weight: bold;
    margin-right: 15px;
}

.asSeenOn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
    height: 60px;
    margin-top: 50px;
    background-color: rgba($color: #000000, $alpha: 0.7);
    border-radius: 10px;
}

.asSeenOnText {
    height: 60px;
    display: inline-block;
    font-size: 40px;
    color: #d5b15e;
    font-weight: bold;
    width: 30%;
    text-align: end;
}

.asSeenOnContent {
    display: inline-block;
    height: 60px;
    width: 70%;
}

.asSeenOnLogos {
    display: flex;
    height: 60px;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    margin: 0;
}

.asSeenOnImages {
    height: 60%;
}

@media only screen and (max-width: 1500px) {
    .watchlistTabText {
        font-size: 12px;
    }
    .speechText {
        font-size: 14px;
    }
    .filterTabElement > p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1400px) {
    .watchlistTabAdd {
        font-size: 14px;
    }
    .watchlistTabRemove {
        font-size: 14px;
    }
    .filterTabElement > p {
        font-size: 13px;
    }
    .table-sentiment-block-content {
        font-size: 24px;
    }
    .asSeenOn {
        height: 50px;
    }
    .asSeenOnText {
        font-size: 35px;
        height: 50px;
    }
    .asSeenOnContent {
        height: 50px;
    }
    .asSeenOnLogos {
        height: 50px;
    }
}

@media only screen and (max-width: 1300px) {
    .table-sentiment-block-content {
        font-size: 20px;
        width: 45%;
    }
    .table-sentiment-flow-content {
        font-size: 16px;
    }
    .filterTabFirstBox {
        font-size: 12px;
    }
    .watchlistTabHeader {
        font-size: 24px;
    }
    .filterTabElement .react-datepicker-wrapper {
        width: 55%;
    }
    .speechText {
        font-size: 12px;
    }
    .asSeenOn {
        height: 45px;
    }
    .asSeenOnText {
        font-size: 29px;
        height: 45px;
    }
    .asSeenOnContent {
        height: 45px;
    }
    .asSeenOnLogos {
        height: 45px;
    }
    .ASC {
        font-size: 12px;
    }
    .accountDetailsInfo {
        font-size: 16px;
    }
    .accountBold {
        font-size: 16px;
    }
    .accountEdit {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1200px) {
    .accountBold {
        font-size: 14px;
    }
    .accountEdit {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1100px) {
    th > span {
        font-size: 0.8em;
    }

    td > div {
        font-size: 0.75em;
    }

    .stockOptionsTable {
        width: 100%;
    }
    .stockOptionsTable.open {
        width: 100%;
    }
    .stockTable {
        width: 100%;
        overflow: auto;
    }

    .watchlistSidebar {
        position: fixed;
        height: 100%;
        left: 100%;
        right: -25%;
        max-width: 300px;
        background-color: black;
    }
    .watchlistSidebar.open {
        position: fixed;
        left: auto;
        overflow: auto;
        right: 0%;
        width: 300px;
        max-width: 300px;
        padding-bottom: 120px;
    }

    // .watchlistSidebar{
    //   position: fixed;
    //   height: 100%;
    //   left: 100%;
    //   right: -60%;
    //   max-width: 100%;
    // }
    // .watchlistSidebar.open{
    //   position: fixed;
    //   left: auto;
    //   right: 0%;
    //   width: 100%;
    //   max-width: 300px;
    // }

    .button.in-pricing {
        font-size: 0.8em;
    }

    .watchlistToggle {
        // width: 2.5em;
        // height: 3em;
        // top: 5%;
        padding: 10px 0;
    }
    .watchlistToggle button {
        font-size: 2em;
    }

    .optionsStats {
        display: block;
        text-align: center;
        justify-content: start;
    }

    .flowRow {
        display: flex;
        width: 100%;
        position: relative;
        margin-top: 10px;
        overflow: hidden;
    }

    .flowRow > div {
        display: inline-block;
        width: 50%;
        text-align: start;
        overflow: hidden;
    }

    .flowMobile > div {
        display: inline-block;
        width: 45%;
        margin: 0 2.5%;
    }

    .flowMobile > .table-sentiment-block-overall {
        width: 50%;
        margin: 0;
    }
    .flowMobile > .table-sentiment-percent {
        height: 45px;
        width: 45px;
    }

    .topTickerMobile button {
        margin: 3px;
        background-color: transparent;
        color: white;
        border-radius: 3px;
    }

    .table-sentiment-block-content {
        width: 60%;
        font-size: 30px;
    }

    .watchlistTabText {
        font-size: initial;
    }
    .watchlistTabHeader {
        font-size: 30px;
    }
    .speechText {
        font-size: initial;
    }
    .filterTabElement > p {
        font-size: 15px;
    }
    .filterTabElement .react-datepicker-wrapper {
        width: 65%;
    }
    .filterTabFirstBox {
        font-size: initial;
    }
    .watchlistTabAdd {
        font-size: initial;
    }
    .watchlistTabRemove {
        font-size: initial;
    }
    .asSeenOn {
        height: 40px;
    }
    .asSeenOnText {
        font-size: 25px;
        height: 40px;
    }
    .asSeenOnContent {
        height: 40px;
    }
    .asSeenOnLogos {
        height: 40px;
    }

    .accountDetailsInfo {
        font-size: 14px;
    }

    .accountHeader {
        font-size: 16px;
    }

    .accountBold {
        font-size: 14px;
    }
    .accountEdit {
        font-size: 14px;
    }
    .accountSubButton {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1000px) {
    .ASC {
        font-size: 11px;
    }

    .accountSubHeader {
        font-size: 20px;
    }

    .accountSubPrice {
        font-size: 16px;
    }

    .accountDetailsInfo {
        font-size: 12px;
    }
    .accountCard {
        height: 40px;
    }
    .accountBold {
        font-size: 12px;
    }
    .accountEdit {
        font-size: 11px;
    }
    .accountMajorityRow > input {
        width: 50%;
        margin-left: 5%;
    }
}

@media only screen and (max-width: 900px) {
    .table-ask-filters {
        margin: auto;
        display: block;
    }
    .table-ask-filters > div {
        display: inline;
        margin-left: 0;
        padding: 0;
    }
    .table-ask-filters > div > button {
        text-align: center;
        width: auto;
        padding: 0;
        margin: 0;
    }
    .table-ask-filters .react-datepicker__input-container > button {
        text-align: center;
        width: auto;
        padding: 0;
        margin: 0;
    }
    .table-ask-filters > div div {
        width: auto;
    }

    .button.in-pricing {
        display: inline;
        padding: 0;
        margin: 0;
        margin-left: 8px;
        width: 17%;
    }

    .button.in-pricing {
        font-size: 0.6em;
    }

    .center {
        display: block;
        width: 100%;
    }
    #examples {
        display: block;
        width: auto;
        overflow-x: scroll;
    }
    #examples > li {
        border: none;
    }

    .watchlistSidebar {
        position: fixed;
        height: 100%;
        left: 100%;
        right: -60%;
        max-width: 100%;
    }
    .watchlistSidebar.open {
        position: fixed;
        left: auto;
        right: 0%;
        width: 100%;
        max-width: 300px;
    }
    .table-sentiment-block-header {
        font-size: 20px;
    }

    .table-sentiment-block-content {
        width: 60%;
        font-size: 24px;
    }
    .asSeenOn {
        width: 95%;
    }
    .asSeenOnText {
        font-size: 24px;
    }
    .accountSubButton {
        font-size: 14px;
    }

    .accountDetailsInfo {
        font-size: 12px;
    }

    .accountHeader {
        font-size: 14px;
    }

    .accountEdit {
        font-size: 9px;
    }
}

@media only screen and (max-width: 800px) {
    .table-sentiment-block-content {
        width: 50%;
        font-size: 22px;
    }
    .flex-column {
        display: block;
        width: 100%;
        margin-top: 20px;
    }
    .accountDetailsInfo {
        font-size: 18px;
    }

    .accountHeader {
        font-size: 18px;
    }

    .accountBold {
        font-size: 18px;
    }
    .accountEdit {
        font-size: 18px;
    }
    .accountMajorityRow > input {
        width: 50%;
        margin-left: 5%;
    }
}

@media only screen and (max-width: 700px) {
    .table-sentiment-block-content {
        width: 45%;
        font-size: 19px;
    }
}

@media only screen and (max-width: 650px) {
    .optionsStats {
        display: block;
        text-align: center;
        justify-content: start;
    }

    .flowRow {
        display: block;
    }

    .flowRow > div {
        display: inline-block;
        width: 100%;
        text-align: start;
        margin-top: 10px;
    }

    .flowMobile > div {
        display: inline-block;
        width: 45%;
        margin: 0 2.5%;
    }

    .flowMobile > .table-sentiment-block-overall {
        width: 50%;
        margin: 0;
    }

    #examples {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .topTickerMobile button {
        margin: 3px;
        background-color: transparent;
        color: white;
        border-radius: 3px;
    }

    .table-sentiment-block-content {
        width: 60%;
        font-size: 40px;
    }
    .navRow {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        color: white;
        text-align: center;
        text-decoration: none;
    }
    .asSeenOn {
        height: 35px;
    }
    .asSeenOnText {
        font-size: 20px;
        height: 35px;
    }
    .asSeenOnContent {
        height: 35px;
    }
    .asSeenOnLogos {
        height: 35px;

        .accountDetailsInfo {
            font-size: 16px;
        }

        .accountHeader {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 600px) {
        .accountBold {
            font-size: 15px;
        }
        .accountEdit {
            font-size: 14px;
        }
        .accountMajorityRow > input {
            width: 50%;
            margin-left: 5%;
        }
    }

    @media only screen and (max-width: 500px) {
        .table-sentiment-block-content {
            width: 60%;
            font-size: 30px;
        }
        .asSeenOn {
            height: 30px;
        }
        .asSeenOnText {
            font-size: 15px;
            height: 30px;
        }
        .asSeenOnContent {
            height: 30px;
        }
        .asSeenOnLogos {
            height: 30px;
        }
        .accountDetailsInfo {
            font-size: 14px;
        }

        .accountHeader {
            font-size: 14px;
        }

        .accountMajorityRow {
            display: block;
            width: 100%;
        }
        .accountMinorityRow {
            display: block;
            width: 100%;
        }
        .accountMMYYBar {
            width: 50%;
        }
        .accountCVCBar {
            width: 50%;
        }
        .accountFullRow {
            margin-top: 30px;
        }
        .accountMajorityRow > p {
            width: 40%;
        }
        .accountMinorityRow {
            margin-top: 20px;
        }
        .accountMinorityRow > p {
            width: 40%;
            margin-left: 5%;
        }
        .accountEdit {
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 430px) {
        .table-sentiment-block-content {
            width: 50%;
            font-size: 24px;
        }
        .asSeenOn {
            height: 25px;
        }
        .asSeenOnText {
            font-size: 15px;
            height: 25px;
        }
        .asSeenOnContent {
            height: 25px;
        }
        .asSeenOnLogos {
            height: 25px;
        }
        .accountDetailsInfo {
            font-size: 12px;
        }

        .accountHeader {
            font-size: 12px;
        }
        .accountEdit {
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 380px) {
        .ASC {
            font-size: 10px;
        }

        .accountSubHeader {
            font-size: 18px;
        }

        .accountSubPrice {
            font-size: 14px;
        }
        .accountCard {
            height: 34px;
        }
        .accountDetailsInfo {
            font-size: 11px;
        }
        .accountEdit {
            font-size: 12px;
        }
        .accountBold {
            font-size: 13px;
        }
    }

    @media only screen and (max-width: 370px) {
        .table-sentiment-block-content {
            width: 45%;
            font-size: 16px;
        }

        .accountSubHeader {
            font-size: 16px;
        }

        .accountSubPrice {
            font-size: 12px;
        }

        .accountSubButton {
            font-size: 11px;
        }
        .asSeenOn {
            height: 25px;
        }
        .asSeenOnText {
            font-size: 10px;
            height: 25px;
            width: 25%;
        }
        .asSeenOnContent {
            height: 25px;
            width: 70%;
        }
        .asSeenOnLogos {
            height: 25px;
        }
    }

    .asSeenOnImages {
        height: 60%;
    }

    .watchlistPulseOn {
        opacity: 1;

        animation: pulse 0.5s infinite;
    }

    .watchlistPulseOff {
        opacity: 0.5;
        transition: 0.5s;
    }

    .watchlistPulseOn:hover {
        opacity: 1;
    }

    .watchlistPulseOff:hover {
        opacity: 1;
    }
}
.rotated {
    transform: rotate(90deg);
    color: white;
}
.top {
    line-height: 12;
    margin-top: 0;
}
.watchlistSymbol {
    margin-top: 100px;
    font-size: 1.25em;
}

.privacy-policy {
    margin: 50px;
    color: #fff;
}
.indent {
    margin-left: 20px;
}
